import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import { AlloawedRoute } from "./Components/AlloawedRoute";

import { InfoProvider } from "./contexts/info";

import { ShoppingCartPage } from "./Pages/ShoppingCartPage";
import { Menu } from "./Pages/Menu";

import { ItemDetails } from "./Pages/ItemDetails";
import { OrderDetails } from "./Pages/OrderDetails";
import { NotFound } from "./Pages/NotFound";
import { PizzaDetails } from "./Pages/PizzaDetails";
import { OrderPlaced } from "./Pages/OrderPlaced";
import { Head } from "./Components/Head";
import { Login } from "./Pages/Login";
import { Layout } from "./Components/Layout";
import { AuthProvider } from "./contexts/auth";

const theme = extendTheme({
  colors: {
    primary: {
      "50": "#ffe6ec",
      "100": "#f7bec8",
      "200": "#eb95a5",
      "300": "#e16b81",
      "400": "#d7415d",
      // "500": "#be2844",
      "500": "#d7415d",
      "600": "#941e35",
      "700": "#6b1325",
      "800": "#420916",
      "900": "#1d0007",
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "gray.600",
        // maxWidth: "780px",
        // pull to refresh (disable)

        overscrollBehaviorY: "contain",
        // display: "flex",
        // justifyContent: "center",
      },
      // styles for the `a`
    },
  },
});

function App(): JSX.Element {
  return (
    <ChakraProvider theme={theme}>
      <InfoProvider>
        <AuthProvider>
          {/* Inject head */}
          <Head />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/menu" element={<Menu />} />
                <Route path="/details/:productId" element={<ItemDetails />} />
                <Route
                  path="/pizza-details/:pizzaId/:sizeId"
                  element={<PizzaDetails />}
                />
                <Route path="/" element={<AlloawedRoute />}>
                  <Route path="/shopping-cart" element={<ShoppingCartPage />} />
                  <Route path="/order-details" element={<OrderDetails />} />
                  <Route path="/order-placed" element={<OrderPlaced />} />
                </Route>

                <Route path="/login" element={<Login />} />

                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </InfoProvider>
    </ChakraProvider>
  );
}

export { App };
