import { create } from "zustand";

interface StoreStatusState {
  storeIsOpen: boolean;
  setStoreIsOpen: (status: boolean) => void;

  /// drawer
  isOpenDrawerStoreClosed: boolean;
  onOpenDrawerStoreClosed: () => void;
  onCloseDrawerStoreClosed: () => void;
}

export const useStoreStatus = create<StoreStatusState>()((set) => ({
  storeIsOpen: false,
  isOpenDrawerStoreClosed: false,

  setStoreIsOpen: (status) => set(() => ({ storeIsOpen: status })),
  onOpenDrawerStoreClosed: () => set(() => ({ isOpenDrawerStoreClosed: true })),
  onCloseDrawerStoreClosed: () =>
    set(() => ({ isOpenDrawerStoreClosed: false })),
}));
