import { Box, Text } from "@chakra-ui/react";
import { useStoreStatus } from "../store/useStoreStatus";

function OpenningHour(): JSX.Element {
  const storeIsOpen = useStoreStatus((state) => state.storeIsOpen);

  return (
    <Box>
      <Text
        color="white"
        bg={storeIsOpen ? "green.400" : "red.400"}
        p="0 1rem 0 1rem"
        borderRadius="1rem"
      >
        {storeIsOpen ? "Aberto" : "Fechado"}
      </Text>
    </Box>
  );
}

export { OpenningHour };
