import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  Box,
  Button,
  Heading,
} from "@chakra-ui/react";
import { BiStore } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";

import { FiArrowLeft } from "react-icons/fi";
import { MdOutlinePlace } from "react-icons/md";
import { useAuth2 } from "../contexts/auth";
import { useInfo } from "../contexts/info";

interface AboutProps {
  isOpen: boolean;
  onClose: () => void;
}

function About({ isOpen, onClose }: AboutProps): JSX.Element {
  const { info } = useInfo();
  const { user } = useAuth2();
  return (
    <Drawer isOpen={isOpen} placement="top" size="full" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" onClick={onClose}>
            <FiArrowLeft />
            <Text ml={2}>Voltar</Text>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Heading as="h6" size="sm" mt={4}>
            Informações sobre a loja
          </Heading>
          <Box fontSize="sm">
            <Flex alignItems="center">
              <BiStore />
              <Text ml="0.5rem"> {info.tradeName}</Text>
            </Flex>
            <Flex alignItems="center">
              <BsTelephone />
              <Text ml="0.5rem">{info.phone.phoneNumber}</Text>
            </Flex>
            <Flex alignItems="center">
              <MdOutlinePlace />
              <Text ml="0.5rem">{`${info.address.street}, ${info.address.number} - ${info.address.neighborhood}`}</Text>
            </Flex>
            <Flex ml={4}>
              <Text ml="0.5rem">{`${info.address.city} - ${info.address.state}`}</Text>
            </Flex>
            <Flex ml={4}>
              <Text ml="0.5rem">{`Cep: ${info.address.zipcode}`}</Text>
            </Flex>
            <Flex mt={2}>
              <Text>CNPJ</Text>
              <Text>{`: ${info.registrationNumber}`}</Text>
            </Flex>
          </Box>
          <Heading as="h6" size="sm" mt={4}>
            Horário de funcionamento
          </Heading>

          {/* <Table variant="unstyled" size="sm">
            <Tbody>
              <Tr>
                <Td px={0}>Domingo</Td>
                <Td>{teste.sunday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Segunda-Feira</Td>
                <Td>{teste.monday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Terça-Feira</Td>
                <Td>{teste.tuesday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Quarta-Feira</Td>
                <Td>{teste.wednesday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Quinta-Feira</Td>
                <Td>{teste.thursday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Sexta-Feira</Td>
                <Td>{teste.friday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Sábado</Td>
                <Td>{teste.saturday}</Td>
              </Tr>
            </Tbody>
          </Table> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export { About };
