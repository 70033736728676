import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useStoreStatus } from "../store/useStoreStatus";

function StoreClosedDrawer(): JSX.Element {
  const isOpenDrawerStoreClosed = useStoreStatus(
    (state) => state.isOpenDrawerStoreClosed
  );
  const onCloseDrawerStoreClosed = useStoreStatus(
    (state) => state.onCloseDrawerStoreClosed
  );

  const navigate = useNavigate();
  return (
    <Drawer
      isOpen={isOpenDrawerStoreClosed}
      placement="bottom"
      onClose={onCloseDrawerStoreClosed}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          A loja está fechada, mas fique a vontade para conferir nosso menu.
        </DrawerBody>

        <DrawerFooter justifyContent="center">
          <Button
            colorScheme="primary"
            variant="outline"
            onClick={() => {
              onCloseDrawerStoreClosed();
              navigate("/menu", { replace: true });
            }}
          >
            Voltar para o menu
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export { StoreClosedDrawer };
