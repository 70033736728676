import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  Flex,
  Stack,
  Divider,
  useDisclosure,
  FlexProps,
  Icon,
  Text,
} from "@chakra-ui/react";
import { memo, useState } from "react";
import { Link } from "react-router-dom";

import { IconType } from "react-icons";
import { FiUser, FiLogIn, FiLogOut } from "react-icons/fi";
import { MdStorefront, MdOutlineContactSupport } from "react-icons/md";
import { HiOutlineTicket } from "react-icons/hi";

import { useInfo } from "../contexts/info";

import { About } from "./About";
import { Info } from "./Info";
import { SignIn } from "./SignIn";
import { Profile } from "./Profile";
import { MyOrders } from "./MyOrders";
import { useShowSigIn } from "../store/useShowSigIn";
import { useAuth2 } from "../contexts/auth";

type ItemProps = FlexProps & {
  label: string;
  icon: IconType;
  show: boolean;
};

function Item({ label, icon, show, ...rest }: ItemProps): JSX.Element | null {
  if (!show) return null;

  return (
    <Flex {...rest} alignItems="center" py={2}>
      <Icon as={icon} mr={2} />
      <Text>{label}</Text>
    </Flex>
  );
}

const items = [
  { label: "Entre ou cadastre-se", icon: FiUser },
  { label: "Meus Dados", icon: FiUser },
  { label: "Pedidos", icon: FiUser },
  { label: "Sobre a loja", icon: FiUser },
  { label: "Contato", icon: FiUser },
];

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

function Sidebar({ isOpen, onClose }: SidebarProps): JSX.Element {
  const { user, signOut } = useAuth2();
  const onOpenSign = useShowSigIn((state) => state.onOpen);
  const [show, setShow] = useState<
    "Profile" | "About" | "Login" | "MyOrders" | null
  >(null);

  function handleClose(): void {
    setShow(null);
  }

  async function handleSignOut(): Promise<void> {
    await signOut();
  }
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent bg="#312E38" color="#fff">
          <DrawerBody>
            {user && <Flex py={4}> {user.email}</Flex>}
            <Divider my={4} />
            <Stack direction="column">
              <Item
                label="Meus dados"
                icon={FiUser}
                show={!!user}
                onClick={() => setShow("Profile")}
              />

              {/* <Item
                label="Entre ou cadastre-se"
                icon={FiLogIn}
                show={!user}
                onClick={() => {
                  onClose();
                  onOpenSign();
                  // setShow("Login");
                }}
              /> */}
              <Item
                label="Pedidos"
                icon={HiOutlineTicket}
                show={!!user}
                onClick={() => setShow("MyOrders")}
              />

              <Item
                label="Sobre a loja"
                icon={MdStorefront}
                show
                onClick={() => setShow("About")}
              />

              <Item label="Contato" icon={MdOutlineContactSupport} show />

              <Item
                label="Sair"
                icon={FiLogOut}
                show={!!user}
                onClick={handleSignOut}
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Profile isOpen={show === "Profile"} onClose={handleClose} />

      <About isOpen={show === "About"} onClose={handleClose} />

      {/* <SignIn isOpen={show === "Login"} onClose={handleClose} /> */}

      <MyOrders isOpen={show === "MyOrders"} onClose={handleClose} />
    </>
  );
}

const SidebarMemo = memo(Sidebar);

export { Sidebar, SidebarMemo };
