import { create } from "zustand";
import { OrderType } from "../types";

interface ParameterState {
  orderType: OrderType;
  setOrderType: (data: OrderType) => void;
}

export const useOrderType = create<ParameterState>()((set) => ({
  orderType: "DELIVERY",
  setOrderType: (orderType) => set(() => ({ orderType })),
}));
