// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6s92gn5EDJ-nI6xEfVyw2Qx2WG9hBpWg",
  authDomain: "appmepedeaiauth.firebaseapp.com",
  projectId: "appmepedeaiauth",
  storageBucket: "appmepedeaiauth.appspot.com",
  messagingSenderId: "683622298815",
  appId: "1:683622298815:web:29bb7d8a2a786ec02c420a",
  measurementId: "G-NVYX551Y17",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
