import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, FormEvent } from "react";
import { FcGoogle } from "react-icons/fc";
import { HiArrowSmLeft } from "react-icons/hi";

import { FiArrowLeft } from "react-icons/fi";
import { RiFacebookFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { SignUp } from "./SignUp";
import { useShowSigIn } from "../store/useShowSigIn";
import { useAuth2 } from "../contexts/auth";

interface SignInProps {
  isOpen: boolean;
  onClose: () => void;
}

function SignIn(): JSX.Element {
  const { isOpen, onOpen, onClose } = useShowSigIn();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signInWithGoogle, signInWithEmailAndPassword } = useAuth2();

  const {
    isOpen: isOpenSignUp,
    onOpen: onOpenSignUp,
    onClose: onCloseSignUp,
  } = useDisclosure();

  async function handleFormSubmit(e: FormEvent): Promise<void> {
    e.preventDefault();
    // // await createUserWithEmailAndPassword({ email, password });
    await signInWithEmailAndPassword({ email, password });
    onClose();
  }

  function handleOpenSignUp(): void {
    onClose();
    onOpenSignUp();
  }
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="top"
        size="full"
        onClose={onClose}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent bg="#f5f5f5">
          <DrawerHeader fontSize="16px">
            <Flex alignItems="center" onClick={onClose} color="primary.500">
              <HiArrowSmLeft size={25} />
              <Text ml={2}>Voltar</Text>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Box textAlign="center" bg="#f5f5f5">
              <Heading>Login</Heading>

              <Box as="form" onSubmit={handleFormSubmit}>
                <FormControl mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    bg="white"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Senha</FormLabel>
                  <Input
                    bg="white"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <Button
                  type="submit"
                  width="full"
                  mt={8}
                  colorScheme="primary"
                  // isLoading={isLoading}
                >
                  Entrar
                </Button>
              </Box>

              <Button colorScheme="primary" variant="link" mt={4}>
                Esqueci minha senha
              </Button>

              <Text mt={4}>ou</Text>

              <Button
                colorScheme="blackAlpha"
                width="full"
                mt={4}
                leftIcon={<FcGoogle />}
                // isLoading={isLoading}
                onClick={async () => {
                  await signInWithGoogle();
                  onClose();
                }}
              >
                Continue com google
              </Button>
              <Button
                colorScheme="facebook"
                width="full"
                mt={4}
                leftIcon={<RiFacebookFill />}
              >
                Continue com facebook
              </Button>

              <Button
                variant="link"
                mt={4}
                onClick={handleOpenSignUp}
                wordBreak="break-word"
              >
                Não tem cadastro? cadastre-se aqui.
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SignUp isOpen={isOpenSignUp} onClose={onCloseSignUp} />
    </>
  );
}

export { SignIn };
