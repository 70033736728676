import { Helmet, HelmetProvider } from "react-helmet-async";
import { useInfo } from "../contexts/info";

const inf = {
  pixelId: "1214305256110113",
  title: "Projeto Pizzaria",
  logo: "logo.png", // mas nao no link,
  minimumOrder: 20.0,
  coverImage: "imagem.png",
  description: "Bem vindo ao Projeto pizzaria",
  segment: "Pizzaria",
};
// CONDICIONAL EM TODOS OS CAMPOS( SE NAO TIVER CADASTRADO)
function Head(): JSX.Element {
  const {
    info: { configuration },
  } = useInfo();

  return (
    <HelmetProvider>
      <Helmet>
        <link
          rel="shortcut icon"
          href="logo128128mepedeai-01.png"
          type="image/png"
        />
        <title>{`${configuration?.title} - Cardápio`}</title>
        <script>{`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window,document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '661807459049345');
           fbq('track', 'PageView');
        `}</script>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-53C84Q9');
            `}
        </script>
        {/* <noscript>
          {`
                      <img
                      height="1"
                      width="1"
                      alt="facebook"
                      src="https://www.facebook.com/tr?id=1214305256110113&ev=PageView
                      &noscript=1"
                    />
        `}
        </noscript> */}
      </Helmet>
    </HelmetProvider>
  );
}

export { Head };
