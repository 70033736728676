/* eslint-disable react/require-default-props */
import { Grid, GridItem, Text, Image } from "@chakra-ui/react";
import { formatPrice } from "../utils/formatPrice";

import plus from "../assets/plus.png";

interface SizeCardProps {
  title: string;
  description?: string;
  numberOfFlavors: number;
  price: number;
  urlImage?: string;
}

function SizeCard({
  description,
  numberOfFlavors,
  title,
  price,
  urlImage,
}: SizeCardProps): JSX.Element {
  return (
    <Grid
      templateColumns="3fr 1fr"
      borderBottom="1px solid #d5d5d5"
      // className="ripple"
    >
      <GridItem p="1rem">
        <Text fontSize="md" fontWeight="base" color="gray.900">
          {numberOfFlavors > 1 ? `${title} ${numberOfFlavors} sabores` : title}
        </Text>
        {description && (
          <Text fontSize="sm" lineHeight="normal">
            {description}
          </Text>
        )}
        <Text>A partir de {formatPrice(price)}</Text>
      </GridItem>

      <GridItem p="1rem 1rem 1rem 0" display="grid" placeItems="center">
        {/* {urlImage && ( */}
        <Image
          src={plus}
          loading="lazy"
          borderRadius="0.5rem"
          // boxSize="80px"
          objectFit="cover"
        />
        {/* )} */}
      </GridItem>
    </Grid>
  );
}

export { SizeCard };

// /* eslint-disable @typescript-eslint/no-use-before-define */
// import {
//   Box,
//   Heading,
//   Grid,
//   GridItem,
//   GridProps,
//   Image,
// } from "@chakra-ui/react";
// import styled from "styled-components";

// import { Product } from "../types";

// import { formatPrice } from "../utils/formatPrice";

// interface PizzaCardProps extends GridProps {
//   product: Product;
//   value: string | undefined;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   handleOnClick: (e: any, valor: number) => void;
// }

// function PizzaCard({
//   product,
//   value,
//   handleOnClick,
//   ...rest
// }: PizzaCardProps): JSX.Element {
//   const { name, description, price, image } = product;

//   const formattedPrice = formatPrice(price);

//   return (
//     <Grid
//       templateColumns="60% 30% 10%"
//       p="1rem"
//       // boxShadow="md"
//       {...rest}
//       bg="white"
//       borderBottom="1px solid #d5d5d5"
//     >
//       <GridItem>
//         <Box>
//           <Heading as="h6" size="xm" mb="0.5rem">
//             {name}
//           </Heading>
//         </Box>
//         <Box mb="0.5rem">{description && description}</Box>

//         <Box display="flex" flexDirection="row" alignItems="center">
//           <Box>+ {formattedPrice}</Box>
//         </Box>
//       </GridItem>
//       <GridItem display="flex" justifyContent="center" alignItems="center">
//         <Image
//           src={image}
//           borderRadius="0.5rem"
//           boxSize="100px"
//           objectFit="cover"
//         />
//       </GridItem>
//       <GridItem display="flex" justifyContent="center" alignItems="center">
//         <Radio
//           value={product.id}
//           readOnly
//           checked={value === product.id}
//           onClick={(e) => handleOnClick(e, product.price)}
//         />
//       </GridItem>
//     </Grid>
//   );
// }

// export { PizzaCard };

// const Radio = styled.input.attrs({ type: "checkbox" })`
//   appearance: none;
//   width: 25px;
//   height: 25px;
//   border: 1px solid black;
//   border-radius: 50%;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;

//   &:checked {
//     border: 2px solid #e53e3e;
//     background: #ffff;
//   }
//   &:checked:before {
//     content: "";
//     position: absolute;
//     background: #e53e3e;
//     height: 15px;
//     width: 15px;
//     border-radius: 50%;
//   }
// `;
