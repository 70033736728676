import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { FcGoogle } from "react-icons/fc";
import { RiFacebookFill } from "react-icons/ri";

import { FormEvent, useState } from "react";
import { useAuth2 } from "../contexts/auth";

function Login(): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    signInWithGoogle,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
  } = useAuth2();

  const navigate = useNavigate();

  async function handleFormSubmit(e: FormEvent): Promise<void> {
    e.preventDefault();
    // await createUserWithEmailAndPassword(email, password);
    await signInWithEmailAndPassword({ email, password });
  }

  return (
    <Box height="100vh" w="100%" p={8} textAlign="center" bg="#f5f5f5">
      <Heading>Login</Heading>
      <Box as="form" onSubmit={handleFormSubmit}>
        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input bg="white" onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Senha</FormLabel>
          <Input bg="white" onChange={(e) => setPassword(e.target.value)} />
        </FormControl>
        <Button type="submit" width="full" mt={8} colorScheme="primary">
          Entrar
        </Button>
      </Box>

      <Button colorScheme="primary" variant="link" mt={4}>
        Esqueci minha senha
      </Button>

      <Text mt={4}>ou</Text>

      <Button
        colorScheme="blackAlpha"
        width="full"
        mt={4}
        leftIcon={<FcGoogle />}
        onClick={signInWithGoogle}
      >
        Continue com google
      </Button>
      <Button
        colorScheme="facebook"
        width="full"
        mt={4}
        leftIcon={<RiFacebookFill />}
      >
        Continue com facebook
      </Button>

      <Button width="full" variant="link" mt={4}>
        Não tem cadastro? cadastre-se aqui
      </Button>
      <Button onClick={() => navigate(-1)}>Voltar</Button>
    </Box>
  );
}

export { Login };

/**
 * const [name, setName] = useState<string | null>(null);
  const auth = getAuth(app);
  const signIn = (): void => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;

        const { user } = result;
        setName(user.displayName);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);

        const { email } = error.customData;

        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
 */
