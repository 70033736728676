/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Icon,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { CgChevronLeft } from "react-icons/cg";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdTimer } from "react-icons/md";
import { VscCircleFilled } from "react-icons/vsc";

import { formatPrice } from "../utils/formatPrice";
import { useCart } from "../store/useCart";

import { useInfo } from "../contexts/info";

import { useParameter } from "../store/useParameter";
import { useOrderType } from "../store/useOrderType";
import { useAddress } from "../store/useAddress";
import { useShowSigIn } from "../store/useShowSigIn";
import { useShowAddress } from "../store/useShowAddress";

import { delay } from "./ItemDetails";
import { getFlavorText } from "./PizzaDetails";

import { OrderTypeRadio } from "../Components/OrderType";
import { useAuth2 } from "../contexts/auth";

function ShoppingCartPage(): JSX.Element {
  // const [buttonState, setButtonState] = useState<"continue" | "openDrawer">(
  //   "continue"
  // );

  const {
    cart,
    totalCart,
    emptyCart,
    incrementCart,
    incrementPizzaCart,
    decreaseCart,
    decreasePizzaCart,
    removeFromCart,
    removePizzaFromCart,
  } = useCart();

  const { selectedAddress } = useAddress();
  const select = selectedAddress();
  const onOpenAddress = useShowAddress((state) => state.onOpen);
  const onOpenSign = useShowSigIn((state) => state.onOpen);
  const parameter = useParameter((state) => state.parameter);
  const orderType = useOrderType((state) => state.orderType);

  const { user } = useAuth2();
  const [isContinueWithoutLogin, setIsContinueWithoutLogin] = useState(false);
  const { info } = useInfo();

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isOpenStore = true;
  useEffect(() => {
    // scroll to the top after render
    window.scrollTo(0, 0);
  }, []);

  let buttonState: "openDrawer" | "continue";

  if (!isOpenStore) {
    buttonState = "openDrawer";
  } else if (totalCart() < info.configuration.minimumOrder) {
    buttonState = "openDrawer";
    // } else if (!user && !isContinueWithoutLogin) {
    //   buttonState = "openDrawer";
  } else if (orderType === "DELIVERY" && !select) {
    buttonState = "openDrawer";
  } else {
    buttonState = "continue";
  }

  // taxa de entrega
  const subTotal = totalCart();
  const deliveryFee = parameter?.delivery?.deliveryFee || 0;
  const total = subTotal + deliveryFee;

  async function handleEmptyCart(): Promise<void> {
    emptyCart();
    toast({
      title: "Sua sacola está vazia!",
      status: "success",
      position: "top",
      duration: 1500,
    });
    await delay();
    navigate("/menu");
  }

  function handleDecrementcart(
    id: string,
    quantity: number,
    type: "DEFAULT" | "PIZZA"
  ): void {
    if (quantity > 1) {
      // eslint-disable-next-line no-unused-expressions
      type === "DEFAULT" ? decreaseCart(id) : decreasePizzaCart(id);
    } else {
      const lengthAfter = cart.length - 1;
      // eslint-disable-next-line no-unused-expressions
      type === "DEFAULT" ? removeFromCart(id) : removePizzaFromCart(id);

      if (lengthAfter === 0) {
        toast({
          title: "Sua sacola está vazia!",
          status: "success",
          position: "top",
          duration: 1500,
        });
        navigate("/menu");
      }
    }
  }

  function handleIncrementCart(id: string, type: "DEFAULT" | "PIZZA"): void {
    switch (type) {
      case "DEFAULT":
        incrementCart(id);
        break;
      case "PIZZA":
        incrementPizzaCart(id);
        break;
      default:
        break;
    }
  }

  function handleDrawerCase(): JSX.Element | null {
    if (!isOpenStore) {
      return (
        <Box p={4}>
          <Text>
            Estamos fechado, mas fique a vontade para ver nosso cardápio.
          </Text>
          <Button
            mt={4}
            w="full"
            colorScheme="primary"
            onClick={() => {
              onClose();
              navigate("/menu");
            }}
          >
            Voltar para o cardápio
          </Button>
        </Box>
      );
    }
    if (totalCart() < info.configuration.minimumOrder) {
      return (
        <Box p={4}>
          <Text>{`O pedido Mínimo desse restaurante é ${formatPrice(
            info.configuration.minimumOrder || 0
          )}, sem contar a taxa de entrega.`}</Text>
          <Center>
            <Button
              colorScheme="primary"
              w="full"
              mt={8}
              onClick={() => {
                onClose();
                navigate("/menu");
              }}
            >
              Adicionar mais itens
            </Button>
          </Center>
        </Box>
      );
    }

    if (!user && !isContinueWithoutLogin) {
      return (
        <Stack p={4} spacing={4}>
          {/* <Button
            colorScheme="primary"
            onClick={() => {
              onClose();
              onOpenSign();
            }}
          >
            Entre ou cadastre-se
          </Button> */}
          <Button
            colorScheme="primary"
            onClick={() => {
              onClose();
              if (select) {
                navigate("/order-details");
              }
              setIsContinueWithoutLogin(true);
              // onOpen();
              // setButtonState("openDrawer");
            }}
          >
            Continuar sem cadastrar
          </Button>
        </Stack>
      );
    }
    if (!select) {
      return (
        <Box p={4}>
          <Center>
            <OrderTypeRadio />
          </Center>
          {orderType === "PICKED_UP" && (
            <Grid
              templateAreas={`" label time-value"
                               "address address"`}
              gridTemplateRows="auto auto"
              gridTemplateColumns="auto auto"
              w="100%"
              py={4}
            >
              <GridItem area="label">Retirar em</GridItem>
              <GridItem area="time-value">
                <Flex alignItems="center">
                  <Flex alignItems="center">
                    <MdTimer />
                    <Text ml="0.5rem">{`${"30"} min`}</Text>
                  </Flex>
                  <Flex mx={1}>
                    <VscCircleFilled size={10} />
                  </Flex>
                  <Flex>
                    <Text>Grátis</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem area="address">
                {`${info.address.street}, ${info.address.number}`}
              </GridItem>
            </Grid>
          )}
          {orderType === "DELIVERY" ? (
            <Button
              colorScheme="primary"
              width="full"
              mt={10}
              onClick={() => {
                onClose();
                onOpenAddress();
              }}
            >
              Selecione um endereço
            </Button>
          ) : (
            <Button colorScheme="primary" width="full" onClick={onClose}>
              Confirmar
            </Button>
          )}
        </Box>
      );
    }
    return null;
  }

  return (
    <>
      <Box
        bg="white"
        display="flex"
        flexDirection="column"
        px="1rem"
        width="100%"
      >
        <Flex
          alignItems=" center"
          justifyContent="space-between"
          // mb={4}

          py={2}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Link to="/menu">
            {/* <CgChevronLeft size={25} color="primary.500" /> */}
            <Icon as={CgChevronLeft} h={6} w={6} color="primary.500" />
          </Link>

          <Center>
            <Text color="gray.900" ml={14}>
              Meu pedido
            </Text>
          </Center>

          <Button
            colorScheme="primary"
            variant="link"
            size="sm"
            onClick={() => handleEmptyCart()}
          >
            Limpar pedido
          </Button>
        </Flex>
        <Link to="/menu">
          <Button
            colorScheme="primary"
            mb={4}
            variant="outline"
            w="full"
            mt="50px"
          >
            Adicionar mais itens
          </Button>
        </Link>
        <Text fontWeight="bold">Itens </Text>
        <Divider mb={4} />
        {cart.map((element) => {
          switch (element.type) {
            case "DEFAULT":
              return (
                <Box key={element.item.id} mb={4}>
                  <Text color="gray.900">{element.item.name}</Text>
                  {element.item.observations && (
                    <Text px={2} fontSize="14px">
                      {`obs: ${element.item.observations}`}
                    </Text>
                  )}
                  <Box px={2}>
                    {element.item.options.map((option) => (
                      <Text key={option.name} fontSize="14px">
                        {`${option.quantity} ${option.name}`}
                      </Text>
                    ))}
                  </Box>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text color="gray.900">
                      {formatPrice(element.item.total)}
                    </Text>
                    <Flex maxW="100px" alignItems="center">
                      <Button
                        borderRadius="0"
                        colorScheme="primary"
                        variant="outline"
                        onClick={() =>
                          handleDecrementcart(
                            element.item.id,
                            element.item.quantity,
                            "DEFAULT"
                          )
                        }
                        size="sm"
                      >
                        {element.item.quantity > 1 ? "-" : <RiDeleteBinLine />}
                      </Button>
                      <Box p={2}> {element.item.quantity}</Box>
                      <Button
                        borderRadius="0"
                        colorScheme="primary"
                        variant="outline"
                        onClick={() =>
                          handleIncrementCart(element.item.id, "DEFAULT")
                        }
                        size="sm"
                      >
                        +
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              );
            case "PIZZA":
              return (
                <Box key={element.pizza.id} mb={4}>
                  <Text color="gray.900">{`${
                    element.pizza.size.name
                  } ${getFlavorText(
                    element.pizza.size.numberOfFlavors
                  )}`}</Text>
                  {element.pizza.observations && (
                    <Text px={2} fontSize="14px">
                      {`obs: ${element.pizza.observations}`}
                    </Text>
                  )}
                  <Box px={2}>
                    {element.pizza.flavors.map((flavor, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Text key={index.toString()} fontSize="14px">
                        {`${flavor.quantity} ${
                          element.pizza.size.numberOfFlavors > 1
                            ? `1/${element.pizza.size.numberOfFlavors} ${flavor.name}`
                            : flavor.name
                        }`}
                      </Text>
                    ))}
                    <Text key={element.pizza.edge.name} fontSize="14px">
                      {`${element.pizza.edge.quantity} ${element.pizza.edge.name}`}
                    </Text>
                  </Box>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text color="gray.900">
                      {formatPrice(element.pizza.total)}
                    </Text>
                    <Flex maxW="100px" alignItems="center">
                      <Button
                        borderRadius="0"
                        colorScheme="primary"
                        variant="outline"
                        onClick={() =>
                          handleDecrementcart(
                            element.pizza.id,
                            element.pizza.quantity,
                            "PIZZA"
                          )
                        }
                        size="sm"
                      >
                        {element.pizza.quantity > 1 ? "-" : <RiDeleteBinLine />}
                      </Button>
                      <Box p={2}> {element.pizza.quantity}</Box>
                      <Button
                        borderRadius="0"
                        colorScheme="primary"
                        variant="outline"
                        onClick={() =>
                          handleIncrementCart(element.pizza.id, "PIZZA")
                        }
                        size="sm"
                      >
                        +
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              );

            default:
              return null;
          }
        })}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem 2rem 1rem 0.5rem",
            width: "100%",
            maxWidth: "780px",
            position: "fixed",
            bottom: 0,
            zIndex: 100,
            borderTop: "1px solid #d5d5d5",
            background: "#ffff",
          }}
        >
          <Box mb={4} w="100%">
            {/* subtotal */}
            <Flex justifyContent="space-between">
              <Text fontSize="16px" color="gray.900">
                Subtotal
              </Text>
              <Text fontSize="16x" color="gray.900">
                {formatPrice(subTotal)}
              </Text>
            </Flex>
            {/* taxa de entrega */}
            <Flex justifyContent="space-between">
              <Text fontSize="16px" color="gray.900">
                Taxa de entrega
              </Text>
              <Text fontSize="16px" color="gray.900">
                {formatPrice(deliveryFee)}
              </Text>
            </Flex>
            {/* total */}
            <Flex justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="gray.900">
                Total
              </Text>
              <Text fontSize="18px" fontWeight="bold" color="gray.900">
                {formatPrice(total)}
              </Text>
            </Flex>
          </Box>
          <Button
            colorScheme="primary"
            w="full"
            height="3rem"
            borderRadius="lg"
            onClick={() =>
              buttonState === "continue" ? navigate("/order-details") : onOpen()
            }
          >
            Continuar
          </Button>
        </div>
      </Box>

      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          {handleDrawerCase()}

          <DrawerBody py={4} />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export { ShoppingCartPage };
