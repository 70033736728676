export function getStore(): string | null {
  const store = null;
  const { host } = window.location;
  const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  if (arr.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    return arr[0];
  }

  return store;
}
