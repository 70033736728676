type MessageProps = {
  name: string;
  message: string;
  orderNumber: string;
  phoneNumber: string;
};
export function sendWhatsapp({
  name,
  message,
  orderNumber,
  phoneNumber,
}: MessageProps): void {
  const url =
    `https://wa.me/55${phoneNumber}?text=` + // Seu numero
    // `Formulário de Contato` +
    // `%0a` + // Mensagem personalizada
    // `%0a` + // Quebra de linha
    `*Nome*: ${name}%0a` + // Dados do formulário
    `*Número do pedido*: ${orderNumber}%0a` +
    `*Mensagem*: ${message} `;

  window.open(url, "_blank")?.focus();
}
