import React, { createContext } from "react";

import { useContext } from "react";
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import { Box } from "@chakra-ui/react";

import capaPrincipal from "../assets/capaPrincipal.jpg";
import { useFetch } from "../hooks/useFetch";
import { PaymentType } from "../Pages/OrderDetails";

import { getStore } from "../utils/getStore";

interface InfoState {
  id: string;
  companyName: string;
  tradeName: string;
  registrationNumber: string;
  phone: {
    phoneNumber: string;
  };
  address: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    zipcode: string;
    complement: string;
  };
  configuration: {
    title: string;
    description: string;
    minimumOrder: number;
    logo: {
      url: string;
    };
    coverImage: {
      url: string;
    };
    segment: string;
  };
  openingHours: {
    startAt: string;
    endAt: string;
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
  }[];
  payments: {
    id: string;
    name: string;
    type: string;
    method: PaymentType;
    brand: string;
    enable: boolean;
  }[];
}

interface InfoContextData {
  info: InfoState;
}
const InfoContext = createContext<InfoContextData>({} as InfoContextData);

function InfoProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  let store = getStore();

  // gambiarra
  if (store === "192") {
    store = "projeto-pizzaria";
  }

  const { data, error } = useFetch(`/info?store=${store}`);

  if (error) {
    // return <NotFound />;
    return (
      <Box
        w="100%"
        h="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Ocorreu um erro ao carregar os dados, tente novamente!
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        w="100%"
        h="100vh"
        backgroundImage={capaPrincipal}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <BeatLoader
          size={25}
          color="white"
          style={{
            position: "absolute",
            top: "calc(50% - 30px)",
            left: "calc(50% - 50px)",
          }}
        />
      </Box>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <InfoContext.Provider value={{ info: data }}>
      {children}
    </InfoContext.Provider>
  );
}

function useInfo(): InfoContextData {
  const context = useContext(InfoContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { InfoProvider, useInfo };
