import { Box } from "@chakra-ui/react";
import { useEffect } from "react";

import { Outlet } from "react-router-dom";
import { useInfo } from "../contexts/info";
import { api } from "../services/api";
import { useStoreStatus } from "../store/useStoreStatus";
import { Address2 } from "./Address2";
import { SignIn } from "./SignIn";
import { StoreClosedDrawer } from "./StoreClosedDrawer";

function Layout(): JSX.Element {
  const { info } = useInfo();
  const setStoreIsOpen = useStoreStatus((state) => state.setStoreIsOpen);

  useEffect(() => {
    api
      .get(`/companies/${info.id}/availability`)
      .then((result) => setStoreIsOpen(result.data.available));
  }, [info.id, setStoreIsOpen]);
  return (
    <Box h="100vh" display="flex" flexDirection="row" justifyContent="center">
      <Box w="100%" maxWidth="780px">
        <Outlet />
      </Box>
      {/* Drawers globals */}
      <SignIn />
      <Address2 />
      <StoreClosedDrawer />
    </Box>
  );
}

export { Layout };
