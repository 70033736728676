import { create } from "zustand";

type Delivery = {
  deliveryFee: number;
  deliveryTime: number;
};

export type Parameter = {
  position: "IN" | "OUT";
  delivery: Delivery | null;
};

interface ParameterState {
  parameter: Parameter;
  setParameter: (data: Parameter) => void;
}

export const useParameter = create<ParameterState>()((set) => ({
  parameter: {} as Parameter,
  setParameter: (data) => set(() => ({ parameter: data })),
}));
