import {
  useRadio,
  chakra,
  Flex,
  Box,
  RadioProps,
  useRadioGroup,
  useToast,
  Stack,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useOrderType } from "../store/useOrderType";
import { OrderType } from "../types";

type CustomRadioProps = RadioProps & {
  label: OrderType;
};

const parse = {
  DELIVERY: "Entrega",
  PICKED_UP: "Retirada",
};

function CustomRadio(props: CustomRadioProps): JSX.Element {
  const { label, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Box
        {...getCheckboxProps()}
        bg={state.isChecked ? "gray.600" : "transparent"}
        color={state.isChecked ? "white" : "gray.600"}
        py={0}
        fontSize="16px"
        rounded="xl"
      >
        <Flex
          border="1px solid"
          borderColor="gray.600"
          px={3}
          py={0}
          rounded="xl"
          {...getLabelProps()}
        >
          {parse[label]}
        </Flex>
      </Box>
    </chakra.label>
  );
}

const values: Array<{ label: OrderType }> = [
  { label: "DELIVERY" },
  { label: "PICKED_UP" },
];

function OrderTypeRadio(): JSX.Element {
  const { orderType, setOrderType } = useOrderType();

  const { getRadioProps, getRootProps } = useRadioGroup({
    value: orderType as string,
    onChange: (teste) => {
      setOrderType(teste as OrderType);
    },
  });

  return (
    <Stack {...getRootProps()}>
      <HStack spacing={4}>
        {values.map((val) => (
          <CustomRadio
            key={val.label}
            label={val.label}
            {...getRadioProps({ value: val.label })}
          />
        ))}
      </HStack>
    </Stack>
  );
}
export { OrderTypeRadio };
