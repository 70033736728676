/* eslint-disable no-nested-ternary */
import {
  Flex,
  useDisclosure,
  Text,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { useEffect } from "react";

import {
  MdOutlineKeyboardArrowRight,
  MdTimer,
  MdOutlineDeliveryDining,
} from "react-icons/md";
import { VscCircleFilled } from "react-icons/vsc";
import { AiOutlineShopping } from "react-icons/ai";
import { useInfo } from "../contexts/info";
import { api } from "../services/api";
import { useAddress } from "../store/useAddress";
import { useOrderType } from "../store/useOrderType";

import { useParameter } from "../store/useParameter";

import { formatPrice } from "../utils/formatPrice";
import { Address2 } from "./Address2";
import { useShowAddress } from "../store/useShowAddress";

function Teste(): JSX.Element {
  const { info } = useInfo();
  const { parameter, setParameter } = useParameter();
  const orderType = useOrderType((state) => state.orderType);
  const { selectedAddress } = useAddress();
  const select = selectedAddress();
  const onOpenAddress = useShowAddress((state) => state.onOpen);

  const toast = useToast();

  // VERFICAR ISSO AQUI !!! TA BUSCANDO dUAS VEzes
  useEffect(() => {
    if (select) {
      api
        .get(`/companies/${info.id}/isWithin`, {
          params: select.location,
        })
        .then((resp) => {
          setParameter(resp.data);
          if (resp.data.position === "OUT") {
            toast({
              title:
                "Descupa, por enquanto não fazemos entregas na sua localização",
              status: "error",
              position: "top",
            });
          }
        });
    }
  }, [info.id, select, selectedAddress, setParameter, toast]);
  return (
    <>
      <Grid
        templateAreas={`"right-icon label value-time left-icon"
                        "right-icon address address left-icon"`}
        gridTemplateRows="auto auto"
        gridTemplateColumns="5% 45% 45% 5%"
        color="blackAlpha.700"
        mt={2}
        onClick={onOpenAddress}
      >
        <GridItem area="label" ml={2}>
          <Text>{orderType === "DELIVERY" ? "Entregar em" : "Retirar em"}</Text>
        </GridItem>
        <GridItem area="value-time">
          {orderType === "DELIVERY" ? (
            selectedAddress() && (
              <Flex alignItems="center">
                <Flex alignItems="center">
                  <MdTimer />
                  <Text ml="0.5rem">{`${parameter.delivery?.deliveryTime} min`}</Text>
                </Flex>
                <Flex mx={1}>
                  <VscCircleFilled size={10} />
                </Flex>
                <Flex>
                  <Text>
                    {formatPrice(parameter.delivery?.deliveryFee || 0)}
                  </Text>
                </Flex>
              </Flex>
            )
          ) : (
            // ESTA FIXO ( PEGAR NO DATABASE)
            <Flex alignItems="center">
              <Flex alignItems="center">
                <MdTimer />
                <Text ml="0.5rem">{`${"30"} min`}</Text>
              </Flex>
              <Flex mx={1}>
                <VscCircleFilled size={10} />
              </Flex>
              <Flex>
                <Text>Grátis</Text>
              </Flex>
            </Flex>
          )}
        </GridItem>
        <GridItem area="left-icon" display="grid" placeItems="center">
          <MdOutlineKeyboardArrowRight size={20} />
        </GridItem>
        <GridItem area="right-icon" display="flex" alignItems="center">
          {orderType === "DELIVERY" ? (
            <MdOutlineDeliveryDining size={20} />
          ) : (
            <AiOutlineShopping size={20} />
          )}
        </GridItem>
        <GridItem area="address" ml={2}>
          {orderType === "DELIVERY" ? (
            select ? (
              <Flex alignItems="center" gap={2}>
                {`${select.street}, ${select.number}`}
              </Flex>
            ) : (
              <Flex alignItems="center" wordBreak="break-word">
                <Text color="gray.900">Selecione um endereço</Text>
              </Flex>
            )
          ) : (
            <Flex alignItems="center" gap={2}>
              {`${info.address.street}, ${info.address.number}`}
            </Flex>
          )}
        </GridItem>
      </Grid>
      {/* Drawer address */}
      <Address2 />
    </>
  );
}

export { Teste };
