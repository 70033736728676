import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Center,
  Stack,
  Button,
} from "@chakra-ui/react";

import { HiArrowSmLeft } from "react-icons/hi";

interface MyOrdersProps {
  isOpen: boolean;
  onClose: () => void;
}

function MyOrders({ isOpen, onClose }: MyOrdersProps): JSX.Element {
  return (
    <Drawer
      isOpen={isOpen}
      placement="top"
      size="full"
      onClose={onClose}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader fontSize="16px">
          <Flex alignItems="center" onClick={onClose} color="primary.500">
            <HiArrowSmLeft size={25} />
            <Text ml={2}>Voltar</Text>
          </Flex>
        </DrawerHeader>

        <DrawerBody />
      </DrawerContent>
    </Drawer>
  );
}

export { MyOrders };
