import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone-lite";
import {
  AuthError,
  getAuth,
  signInWithPopup,
  PhoneAuthProvider,
  updateProfile,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  RecaptchaVerifier,
} from "firebase/auth";
import { useState, useCallback } from "react";
import { FcGoogle } from "react-icons/fc";

import { RiFacebookFill } from "react-icons/ri";
import { HiArrowSmLeft } from "react-icons/hi";
import { MdOutlineMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { Credentials } from "../types";
import { app } from "../services/firebase";
import { phoneNumberMask, phoneMask } from "../utils/phoneNumberMask";
import { useAuth2 } from "../contexts/auth";

interface SignUpProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
}

const phoneRegex =
  /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/;
const schema = Yup.object().shape({
  name: Yup.string().required("Campo nome é obrigatório"),
  email: Yup.string()
    .required("Campo email é obrigatório")
    .email("Digite um email válido"),
  phoneNumber: Yup.string()
    .required("Campo telefone é obrigatório")
    .min(14, "Digite um numero valido"),
  //   .matches(phoneRegex, {
  //   excludeEmptyString: true,
  //   message: "Digite um numero valido",
  // }),
  password: Yup.string()
    .required("Campo senha é obrigatório")
    .min(6, "A senha deve ter no mínimo 6 caracteres"),
});

function AuthErrorMessage(value: string): string {
  let message: string;
  switch (value) {
    case "auth/email-already-in-use": {
      message = "Já existe um conta vinculada ao email informado.";
      break;
    }
    case "auth/invalid-email": {
      message = "Email inválido ";
      break;
    }
    case "auth/weak-password": {
      message = "Senha deve ter no mínimo 6 caracteres";
      break;
    }

    default: {
      message = "Outro error";
      break;
    }
  }

  return message;
}

function SignUp({ isOpen, onClose }: SignUpProps): JSX.Element {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  const { createUserWithEmailAndPassword, updateUserProfile } = useAuth2();

  const [isSignUpWithMail, setIsSignUpWithMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth(app);

  const navigate = useNavigate();
  const toast = useToast();

  function handleClose(): void {
    setIsSignUpWithMail(false);
    reset();
    onClose();
  }

  // async function handleFormSubmit(data: FormValues): Promise<void> {
  //   setIsLoading(true);
  //   try {
  //     const result = await firebaseCreateUserWithEmailAndPassword(
  //       auth,
  //       data.email,
  //       data.password
  //     );

  //     if (auth.currentUser) {
  //       await updateProfile(auth.currentUser, { displayName: data.name });
  //     }

  //     /// ///////////////////////////////////////////////////////////////////////////////
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "sign-with-email-password",
  //       {
  //         size: "invisible",
  //         callback: (response: any) => {
  //           // reCAPTCHA solved, allow signInWithPhoneNumber.
  //           // onSignInSubmit();
  //         },
  //       },
  //       auth
  //     );
  //     // const applicationVerifier = new RecaptchaVerifier(
  //     //   "recaptcha-container",
  //     //   {},
  //     //   auth
  //     // );
  //     const phoneProvider = new PhoneAuthProvider(auth);
  //     const verificationId = await phoneProvider.verifyPhoneNumber(
  //       "+5532984136060",
  //       window.recaptchaVerifier
  //     );
  //     // Obtain the verificationCode from the user.

  //     // Obtain the verificationCode from the user.
  //     // const phoneCredential = PhoneAuthProvider.credential(
  //     //   verificationId,
  //     //   verificationCode
  //     // );
  //     // await updatePhoneNumber(auth.currentUser, phoneCredential);

  //     /// //////////////////////////////////////////////////////////////////////

  //     toast({
  //       title: "Conta criada com sucesso.",
  //       description: "Seja bem-vindo!",
  //       status: "success",
  //       position: "top",
  //     });
  //     handleClose();
  //   } catch (err) {
  //     console.log(err);
  //     const message = AuthErrorMessage((err as AuthError).code);
  //     toast({
  //       title: message,
  //       status: "error",
  //       position: "top",
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  async function handleFormSubmit(data: FormValues): Promise<void> {
    try {
      await createUserWithEmailAndPassword({
        email: data.email,
        password: data.password,
      });

      await updateUserProfile({ displayName: data.name });
    } catch (err) {
      console.log(err);
      const message = AuthErrorMessage((err as AuthError).code);
      toast({
        title: message,
        status: "error",
        position: "top",
      });
    }
  }

  function handlePhoneNumberMask(e: any): any {
    let { value } = e.currentTarget;
    value = phoneMask(value);
    e.currentTarget.value = value;
    return value;
  }
  return (
    <Drawer
      isOpen={isOpen}
      placement="top"
      size="full"
      onClose={onClose}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent bg="#f5f5f5">
        <DrawerHeader fontSize="16px">
          <Flex alignItems="center" onClick={handleClose} color="primary.500">
            <HiArrowSmLeft size={25} />
            <Text ml={2}>Voltar</Text>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Box textAlign="center">
            <Heading size="lg">Criar conta</Heading>
            {isSignUpWithMail && (
              <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
                <FormControl mt={4} isInvalid={!!errors.name}>
                  <FormLabel mb={0}>Nome</FormLabel>
                  <Input {...register("name")} bg="white" />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl mt={4} isInvalid={!!errors.email}>
                  <FormLabel mb={0}>Email</FormLabel>
                  <Input bg="white" {...register("email")} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl mt={4} isInvalid={!!errors.phoneNumber}>
                  <FormLabel mb={0}>Número do seu telefone</FormLabel>
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        // placeholder="(XX)XXXXX-XXXX"
                        type="tel"
                        maxLength={15}
                        bg="white"
                        onKeyUp={(e) =>
                          field.onChange(handlePhoneNumberMask(e))
                        }
                      />
                    )}
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                  />
                  <FormErrorMessage>
                    {errors.phoneNumber?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mt={4} isInvalid={!!errors.password}>
                  <FormLabel mb={0}>Senha</FormLabel>
                  <Input bg="white" {...register("password")} />
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  id="sign-with-email-password"
                  type="submit"
                  width="full"
                  mt={8}
                  colorScheme="primary"
                  isLoading={isLoading}
                >
                  Confirmar
                </Button>
              </Box>
            )}
            {!isSignUpWithMail && (
              <Button
                colorScheme="teal"
                width="full"
                mt={4}
                leftIcon={<MdOutlineMail />}
                onClick={() => setIsSignUpWithMail(true)}
              >
                Continue com email e senha
              </Button>
            )}

            {!isSignUpWithMail && (
              <>
                <Text mt={4}>ou</Text>

                <Button
                  colorScheme="blackAlpha"
                  width="full"
                  mt={4}
                  leftIcon={<FcGoogle />}
                >
                  Continue com google
                </Button>
                <Button
                  colorScheme="facebook"
                  width="full"
                  mt={4}
                  leftIcon={<RiFacebookFill />}
                >
                  Continue com facebook
                </Button>
              </>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export { SignUp };
