import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Center,
  Stack,
  Button,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { HiArrowSmLeft } from "react-icons/hi";
import { useAuth2 } from "../contexts/auth";

interface ProfileProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

function Profile({ isOpen, onClose }: ProfileProps): JSX.Element {
  // const { user, updateUser, error } = useAuth();
  const { user } = useAuth2();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phoneNumber", user.phoneNumber);
    }
  }, [setValue, user]);

  // async function handleFormSubmit(data: FormValues): Promise<void> {
  //   try {
  //     await updateUser();
  //   } catch (err) {
  //     console.log(err);
  //     console.log(error);
  //   }
  // }

  return (
    <Drawer
      isOpen={isOpen}
      placement="top"
      size="full"
      onClose={onClose}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader fontSize="16px">
          <Flex alignItems="center" onClick={onClose} color="primary.500">
            <HiArrowSmLeft size={25} />
            <Text ml={2}>Voltar</Text>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Center fontWeight="bold">Dados pessoais</Center>
          <Stack
            as="form"
            spacing={8}
            // onSubmit={handleSubmit(handleFormSubmit)}
          >
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input {...register("name")} />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input {...register("email")} />
            </FormControl>
            <FormControl>
              <FormLabel>Número do celular</FormLabel>
              <Input {...register("phoneNumber")} />
            </FormControl>

            <Button type="submit" colorScheme="primary">
              Salvar alterações
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export { Profile };
