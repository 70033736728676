import { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";

import { useCart } from "../store/useCart";
import { formatPrice } from "../utils/formatPrice";

import { getFlavorText } from "./PizzaDetails";
import { useInfo } from "../contexts/info";
import { sendWhatsapp } from "../utils/sendWhatsapp";

function OrderPlaced(): JSX.Element {
  const { cart, totalCart } = useCart();
  const { info } = useInfo();
  const { orderType, address, costumer, delivery, orderNumber } = useLocation()
    .state as {
    orderType: string;
    orderNumber: number;
    delivery: any;
    address: any | undefined;
    costumer: any;
  };

  // scroll to the top after render
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // taxa de entrega
  const subTotal = totalCart();

  const total: number = subTotal + (delivery.deliveryFee || 0);

  return (
    <Box
      w={{ base: "full", md: "780px" }}
      bg="white"
      display="flex"
      flexDirection="column"
      px={4}
    >
      <Flex
        alignItems=" center"
        justifyContent="center"
        // mb={4}
        h="4rem"
        position="sticky"
        zIndex="sticky"
        bg="white"
      >
        <Heading as="h3" size="md">
          <Center>Resumo do pedido</Center>
        </Heading>
      </Flex>

      <Box
        // bg="primary.100"
        color="primary.700"
        border="1px solid "
        borderColor="primary.700"
        borderRadius="md"
        p={4}
        mb={4}
        wordBreak="break-all"
      >
        <Text>
          {" "}
          Seu Pedido foi encaminhado para loja, qualquer dúvida entre em contato
          com a loja.
        </Text>
        {/* <Text>{`Tel: ${info.phone.phoneNumber}`}</Text> */}
      </Box>
      <Center my={4}>
        <Text>{`Pedido Nº ${orderNumber.toString().padStart(4, "0")}`}</Text>
      </Center>
      <Text fontWeight="bold">Itens </Text>
      <Divider mb={4} />
      {cart.map((element) => {
        switch (element.type) {
          case "DEFAULT":
            return (
              <Box key={element.item.id} mb={4}>
                <Flex justifyContent="space-between">
                  <Text color="gray.900">{`${element.item.quantity} ${element.item.name}`}</Text>
                  {/* <Text>{formatPrice(element.item.unitPrice)}</Text> */}
                </Flex>
                {element.item.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.item.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.item.options.map((option) => (
                    <Text key={option.name} fontSize="14px">
                      {`${option.quantity} ${option.name}`}
                    </Text>
                  ))}
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="flex-end">
                  <Text color="gray.900">
                    {formatPrice(element.item.total)}
                  </Text>
                  {/* right */}
                </Flex>
              </Box>
            );
          case "PIZZA":
            return (
              <Box key={element.pizza.id} mb={4}>
                <Flex justifyContent="space-between">
                  <Text color="gray.900">{` ${element.pizza.quantity} ${
                    element.pizza.size.name
                  } ${getFlavorText(
                    element.pizza.size.numberOfFlavors
                  )}`}</Text>
                  {/* <Text>{formatPrice(element.pizza.unitPrice)}</Text> */}
                </Flex>
                {element.pizza.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.pizza.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.pizza.flavors.map((flavor, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Text key={index.toString()} fontSize="14px">
                      {`${flavor.quantity} ${
                        element.pizza.size.numberOfFlavors > 1
                          ? `1/${element.pizza.size.numberOfFlavors} ${flavor.name}`
                          : flavor.name
                      }`}
                    </Text>
                  ))}
                  <Text key={element.pizza.edge.name} fontSize="14px">
                    {`${element.pizza.edge.quantity} ${element.pizza.edge.name}`}
                  </Text>
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="flex-end">
                  <Text color="gray.900">
                    {formatPrice(element.pizza.total)}
                  </Text>
                  {/* right */}
                </Flex>
              </Box>
            );

          default:
            return <div key={v4()}>Elemento do carrinho invalido</div>;
        }
      })}
      <Divider />
      <Box mt={2}>
        <Flex w="100%" justifyContent="space-between">
          <Text>Subtotal</Text>
          <Text>{formatPrice(totalCart())}</Text>
        </Flex>
        <Flex w="100%" justifyContent="space-between">
          <Text>Taxa de entrega</Text>
          {delivery ? (
            <Text>{formatPrice(delivery.deliveryFee)}</Text>
          ) : (
            <Text>R$ 0,00</Text>
          )}
        </Flex>
        <Flex w="100%" justifyContent="space-between" fontWeight="bold">
          <Text>Total</Text>
          <Text>{formatPrice(total)}</Text>
        </Flex>
      </Box>

      <Box mt={4}>
        <FormLabel mb="0">
          {orderType === "DELIVERY" ? (
            <Text>Endereço para entrega:</Text>
          ) : (
            <Text>Retirar no local:</Text>
          )}
        </FormLabel>
        <Flex>
          <Text>{`${address.street}, ${address.number}`}</Text>
          {address.complement && (
            <Text ml={1}>{`- ${address.complement}`}</Text>
          )}
        </Flex>
        <Text>
          {`${address.neighborhood} - ${address.city} - ${address.uf}`}
        </Text>
        <Text>{`Tel: ${costumer.phoneNumber}`} </Text>
      </Box>

      <Button
        w="full"
        mt={6}
        colorScheme="primary"
        variant="outline"
        onClick={() =>
          sendWhatsapp({
            phoneNumber: "3230835308",
            name: "Andre Luiz",
            orderNumber: orderNumber.toString().padStart(4, "0"),
            message: "Boa noite, acabei de fazer um pedido!",
          })
        }
      >
        Iniciar chat com a loja
      </Button>
    </Box>
  );
}

export { OrderPlaced };

/**
 *   street: string;
  number: string;
  neighborhood: string;
  city: string;
  uf: string;
  zipCode: string;
  complement: string;
  reference: string;
 */
