import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  updateProfile,
  User as UserFirebase,
  AuthError,
} from "firebase/auth";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useContext } from "react";
import { app, db } from "../services/firebase";

export type User = {
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
};

const provider = new GoogleAuthProvider();

type Credentials = {
  email: string;
  password: string;
};

type UpdateProfile = {
  displayName?: string | null;
  photoURL?: string | null;
};

interface AuthContextData {
  user: User | null;
  signInWithGoogle: () => Promise<void>;
  createUserWithEmailAndPassword: (credentials: Credentials) => Promise<void>;
  signInWithEmailAndPassword: (credentials: Credentials) => Promise<void>;
  updateUserProfile: (data: UpdateProfile) => Promise<void>;
  signOut: () => Promise<void>;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (loggedUser) => {
      if (loggedUser) {
        const { displayName, email, phoneNumber } = loggedUser as UserFirebase;
        const docRef = doc(db, "users", "userId");
        getDoc(docRef).then((docSnap) => console.log(docSnap.data()));
        setUser({ name: displayName, email, phoneNumber });
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const signInWithGoogle = useCallback(async () => {
    await signInWithPopup(auth, provider);
  }, [auth]);

  const createUserWithEmailAndPassword = useCallback(
    async (credentials: Credentials) => {
      await firebaseCreateUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
    },
    [auth]
  );

  const updateUserProfile = useCallback(
    async (data: UpdateProfile) => {
      if (!auth.currentUser) {
        return;
      }
      await updateProfile(auth.currentUser, data);
      setUser({
        ...user,
        name: data.displayName,
      });
    },
    [auth, user]
  );

  // const updateUserPhoneNumber = useCallback(
  //   async (phoneNumber: string) => {
  //     setIsLoading(true);
  //     setError(undefined);
  //     if (!auth.currentUser) {
  //       return;
  //     }
  //     try {
  //       await updateProfile(auth.currentUser, data);
  //     } catch (err) {
  //       setError(err as AuthError);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [auth]
  // );

  const signInWithEmailAndPassword = useCallback(
    async (credentials: Credentials) => {
      await firebaseSignInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
    },
    [auth]
  );

  const signOut = useCallback(async () => {
    await auth.signOut();
  }, [auth]);

  const value = useMemo(
    () => ({
      user,
      signInWithGoogle,
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      updateUserProfile,
      signOut,
    }),
    [
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signOut,
      updateUserProfile,
      user,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth2(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth2 };
