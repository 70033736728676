import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  Input,
  Button,
  Text,
  Box,
  FormLabel,
  Radio,
  Stack,
  Flex,
  useDisclosure,
  FormControl,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";

import { MdOutlinePlace, MdAdd, MdOutlineDeliveryDining } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { CgChevronLeft } from "react-icons/cg";
import Geocode from "react-geocode";

import { useForm } from "react-hook-form";
import { useState } from "react";
import { memo } from "react";
import { api } from "../services/api";
import { useUser } from "../store/useUser";
import { useAddress } from "../store/useAddress";
import { useInfo } from "../contexts/info";
import { useOrderType } from "../store/useOrderType";
import { useShowAddress } from "../store/useShowAddress";

// interface AddressProps {
//   isOpen: boolean;
//   onClose: () => void;
// }

interface FormData {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  uf: string;
  zipCode: string;
  complement: string;
  reference: string;
}

function Address2(): JSX.Element {
  const { info } = useInfo();
  const { isOpen, onClose } = useShowAddress();
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const setOrderType = useOrderType((state) => state.setOrderType);
  const adresses = useAddress((state) => state.adresses);
  const addAddress = useAddress((state) => state.addAddress);
  // executei a funcao diretamente( mudar para trazer diretamente igual o recoil)
  const selectedAddress = useAddress((state) => state.selectedAddress)();
  const removeAddress = useAddress((state) => state.removeAddress);
  const markAddressAsSelected = useAddress(
    (state) => state.markAddressAsSelected
  );
  const outDeliveryAreaDrawer = useDisclosure();

  /// geocode
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY!);
  Geocode.setLanguage("br");
  Geocode.setRegion("br");
  Geocode.setLocationType("ROOFTOP");

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  async function handleSearchCep(): Promise<void> {
    try {
      const cep = getValues("zipCode");
      if (cep) {
        if (cep.replace("-", "").length < 8) {
          // cep: 99999-999  8 digits
          throw new Error();
        }
        const result = await api.get(
          `${process.env.REACT_APP_VIACEP_URL}/${cep.replace("-", "")}/json/`
        );

        if (result.data.erro) {
          setShowForm(true);
        } else {
          setValue("street", result.data.logradouro);
          setValue("neighborhood", result.data.bairro);
          setValue("city", result.data.localidade);
          setValue("uf", result.data.uf);
          setShowForm(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFormSubmit(data: FormData): Promise<void> {
    try {
      const response = await Geocode.fromAddress(
        // "R. Isabel Bastos, 10 - Passos, Juiz de Fora - MG, 36025-050"
        `${data.street}, ${data.number}, ${data.city} - ${data.uf}, ${data.zipCode}`
      );

      const { lat, lng } = response.results[0].geometry.location;

      // faz a busca aqui
      const result = await api.get(`/companies/${info.id}/isWithin`, {
        params: { lat, lng },
      });

      if (result.data.position === "IN") {
        addAddress({
          ...data,
          zipCode: data.zipCode.replace("-", ""),
          selected: true,
          location: { lat, lng },
        });
        reset();
        setIsNewAddress(false);
        setShowForm(false);
        onClose();
      } else {
        reset();
        setIsNewAddress(false);
        setShowForm(false);
        onClose();
        outDeliveryAreaDrawer.onOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* <Box onClick={onOpen}>
        {selectedAddress ? (
          <Flex alignItems="center" gap={2}>
            <MdOutlinePlace />
            {`${selectedAddress.street}, ${selectedAddress.number}`}
          </Flex>
        ) : (
          <Flex alignItems="center" gap={2}>
            <MdOutlineDeliveryDining size={20} />
            Selecione um endereço
          </Flex>
        )}
      </Box> */}
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader mt={16}>
            <Flex alignItems="center" gap={12}>
              <CgChevronLeft
                size={25}
                onClick={() => {
                  reset();
                  setIsNewAddress(false);
                  setShowForm(false);
                  onClose();
                }}
              />
              Endereço para entrega
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {!isNewAddress ? (
              <>
                <Flex>
                  <Button
                    leftIcon={<MdAdd size={20} />}
                    colorScheme="primary"
                    variant="outline"
                    width="full"
                    onClick={() => setIsNewAddress(true)}
                  >
                    Adicionar Endereço
                  </Button>
                </Flex>
                <Text mt={8} size="lg" fontWeight="bold">
                  Meus Endereços
                </Text>

                <Stack direction="column">
                  {adresses.map((address) => (
                    <Flex key={address.id} alignItems="center">
                      <Radio
                        size="lg"
                        colorScheme="primary"
                        isChecked={address.selected}
                        onClick={() => markAddressAsSelected(address.id)}
                      />
                      <Box p={4} fontSize="16px">
                        <Text>{`${address.street}, ${address.number} - ${address.complement}`}</Text>
                        <Text>{`${address.neighborhood}, ${address.city} - ${address.uf} - ${address.zipCode}`}</Text>
                      </Box>
                      <RiDeleteBinLine
                        size={25}
                        cursor="pointer"
                        onClick={() => removeAddress(address.id)}
                      />
                    </Flex>
                  ))}
                </Stack>
              </>
            ) : (
              <Stack
                as="form"
                onSubmit={handleSubmit(handleFormSubmit)}
                spacing={4}
              >
                <Flex>
                  <Input
                    {...register("zipCode")}
                    placeholder="Informe o CEP"
                    onChange={(e) => {
                      let { value } = e.currentTarget;
                      e.currentTarget.maxLength = 9;
                      value = value.replace(/\D/g, "");
                      value = value.replace(/(\d{5})(\d)/, "$1-$2");
                      e.currentTarget.value = value;
                      return value;
                    }}
                  />
                  <Button
                    colorScheme="primary"
                    ml={2}
                    onClick={() => handleSearchCep()}
                  >
                    Buscar
                  </Button>
                </Flex>
                <Button variant="link" onClick={() => setShowForm(true)}>
                  Não sei meu cep
                </Button>
                {showForm && (
                  <>
                    <FormControl isInvalid={!!errors.street}>
                      <FormLabel>Endereço</FormLabel>
                      <Input
                        {...register("street", {
                          required: "Campo obrigatório",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.street?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <HStack>
                      <FormControl isInvalid={!!errors.number}>
                        <FormLabel>Número</FormLabel>
                        <Input
                          {...register("number", {
                            required: "Campo obrigatório",
                          })}
                        />
                        <FormErrorMessage>
                          {errors.number?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Complemento</FormLabel>
                        <Input {...register("complement")} />
                      </FormControl>
                    </HStack>
                    <FormControl isInvalid={!!errors.neighborhood}>
                      <FormLabel>Bairro</FormLabel>
                      <Input
                        {...register("neighborhood", {
                          required: "Campo obrigatório",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.neighborhood?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Ponto de Referência</FormLabel>
                      <Input {...register("reference")} />
                    </FormControl>
                    <HStack>
                      <FormControl isInvalid={!!errors.city}>
                        <FormLabel>Cidade</FormLabel>
                        <Input
                          {...register("city", {
                            required: "Campo obrigatório",
                          })}
                        />
                        <FormErrorMessage>
                          {errors.city?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.uf}>
                        <FormLabel>UF</FormLabel>
                        <Input
                          {...register("uf", { required: "Campo obrigatório" })}
                        />
                        <FormErrorMessage>
                          {errors.uf?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                    <Button type="submit" colorScheme="primary" w="full">
                      Salvar endereço
                    </Button>{" "}
                  </>
                )}
              </Stack>
            )}
          </DrawerBody>

          <DrawerFooter />
        </DrawerContent>
      </Drawer>
      {/* Fora da are ade entrega */}
      <Drawer
        isOpen={outDeliveryAreaDrawer.isOpen}
        placement="top"
        size="full"
        onClose={outDeliveryAreaDrawer.onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Flex>Voltar</Flex>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              h="60%"
            >
              <Text>
                Desculpe! No momento não fazemos entrega no endereço informado,
                mas você pode retirar na loja.
              </Text>
              <Flex justifyContent="center" mt={4}>
                <Button
                  mr={4}
                  colorScheme="primary"
                  variant="outline"
                  onClick={outDeliveryAreaDrawer.onClose}
                >
                  Voltar
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={() => {
                    setOrderType("PICKED_UP");
                    outDeliveryAreaDrawer.onClose();
                  }}
                >
                  Retirar na loja
                </Button>
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export { Address2 };
