import { create } from "zustand";
import { persist } from "zustand/middleware";
import { v4 as V4 } from "uuid";
import { Address } from "../types";

interface AddressState {
  adresses: Address[];

  addAddress: (address: Omit<Address, "id">) => void;
  removeAddress: (id: string) => void;
  markAddressAsSelected: (id: string) => void;
  selectedAddress: () => Address | undefined;
}

export const useAddress = create<AddressState>()(
  persist(
    (set, get) => ({
      adresses: [],

      addAddress: (address) =>
        set((state) => ({
          adresses: [
            ...state.adresses.map((item) => ({
              ...item,
              selected: false,
            })),
            { ...address, id: V4() },
          ],
        })),
      removeAddress: (id) =>
        set((state) => ({
          adresses: state.adresses.filter((address) => address.id !== id),
        })),
      markAddressAsSelected: (id) =>
        set((state) => ({
          adresses: state.adresses.map((address) => {
            if (address.id === id) {
              return {
                ...address,
                selected: true,
              };
            }
            return {
              ...address,
              selected: false,
            };
          }),
        })),

      selectedAddress: () => {
        const { adresses } = get();
        return adresses.find((address) => address.selected === true);
      },
    }),
    {
      name: "projeto-pizzaria:adresses", // name of item in the storage (must be unique)
    }
  )
);
